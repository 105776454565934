.App-message {
  margin: 1rem;
  font-size: 1.25rem;
}

.ExperienceAlert{
  padding: 0 !important;
}
.ExperienceAlert{
  font-size: 1.2rem;
}

.alert-dismissible .close{
  left: 0;
  right: auto;
  padding: .5rem .75rem !important;
}
.ExperienceAlert .alert-dismissible{
}
 .alert {
   padding: .75rem .75rem !important;
 }

  /* Vertically centers content */
.vertical-center {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
}

/* Vertically centers content */
.PreviewCampaign .vertical-center {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
}

.PreviewCampaign .EngagementCard_childContainer  {
  margin: 0 -15px;
}

.EngagementCard{
  display: block;
}

.EngagementCard .EngagementCard_childContainer{
  border-radius: 0.5rem !important;
  overflow: hidden !important;
}

.PreviewCampaign {
  padding: 0.25rem;
  height: 100%;
}

.PreviewCampaign .campaign-layout{
  padding-bottom:0;
  height: 100% !important;
}

.PreviewCampaign .CampaignPreviewCard {
  margin: auto;
  min-width: 200px;
  max-width: 400px;
}

/* Fix for bootstrap, this class available from v4.1.1, remove this when bootstrap is updated */
.PreviewCampaign .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.PreviewCampaign .ProgressBar_bar {
  background-color: #eceeef;
  border-radius: 0.25rem;
  height: 0.5rem;
  max-width: 75%;
  margin: 1.1rem auto;
}

.PreviewCampaign .CloseButton_btn {
  font-size: 1.2rem;
}

.PreviewCampaign .progress-bar {
  background-color: #e96224 !important;
  transition: width 175ms ease-out; }

.PreviewCampaign .btn-link, .ConclusionCardModalFooter .btn-link{
  color: black !important;
  background-color: transparent !important;
  border-width: 0;
}

.PreviewCampaign .ConclusionCard_doneContainer {
  margin-top: 0 !important;
}

.PreviewCampaign .campaign-progress-bar-layout{
  height: 2.5rem;
}

.PreviewCampaign .ConclusionCard_toast_img{
  /*transform: scale(0.8);*/
  height: 100px;
  margin: 0.5rem auto;
}

.PreviewCampaign .btn.ChooseCard_answer, .PreviewCampaign .btn.QaCard_answer {
  padding: 0.25rem 0.5rem !important;
  line-height: 1rem !important;
  white-space: normal;
  width: 100%;
  font-size: 0.7rem;
  min-height: 2.5rem;
  max-height: 3.3rem;
}

.PreviewCampaign .CardLongText{
  font-size: 0.55rem !important;
}

.PreviewCampaign .QaCard_correct-answer-text {
  padding: 0.75rem 1rem !important;
  line-height: 1rem !important;
  white-space: normal;
  width: 100%;
  font-size: 0.8rem;
}

.PreviewCampaign .ChooseCard_question, .PreviewCampaign .QaCard_question  {
  padding: 0.75rem 1rem !important;
  font-size: 0.8rem;
  line-height: 1rem !important;
  max-height: 4.3rem;
}

.PreviewCampaign .ThisOrThatCard_or {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
  font-size: 0.6rem;
}

.PreviewCampaign .ConclusionCard_text {
  padding: 0.5rem 0rem;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.PreviewCampaign .ConclusionCard_text p{
  margin: 0;
}

.PreviewCampaign .ConclusionCard_text h3{
  font-size: 0.7rem;
  line-height: 1rem !important;
}

.PreviewCampaign .ConclusionCard_rating{
  margin-top: 0px;
}

.PreviewCampaign .btn#buttonCTALink{
  padding: 0.5rem !important;
  line-height: 1rem !important;
  height: 2rem;
}

.PreviewCampaign #buttonCTALink{
  border-radius: 2rem;
}

.PreviewCampaign .doneButton{
  height: 2rem;
}

.PreviewCampaign .ConclusionCard_top{
  top: 2.5rem;
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
}

.PreviewCampaign .container,
.PreviewCampaign .EngagementCard,
.PreviewCampaign .EngagementCard_childContainer,
.PreviewCampaign .ConclusionCard_top .row{
  height:100%;
}

.PreviewCampaign_PreviewCard {
  padding: 0.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.PreviewCampaign .ConclusionCard_logoContainer{
  background-color: #F7F7F7;
}

@media (device-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {

  .PreviewCampaign .campaign-progress-bar-layout {
    height: 2rem;
  }

  .PreviewCampaign .ConclusionCard_text {
    padding: 0.10rem 0rem;
    font-size: 0.7rem;
    margin-bottom: 0;
  }

  .PreviewCampaign .ConclusionCard_top{
    top: 2rem;
    bottom: 0;
  }

  .PreviewCampaign .doneButton {
    height: 1rem;
    padding: 0;
  }

  .PreviewCampaign blockquote {
    margin: 0;
  }
}


/* Fits to parent element's size using position absolute */
.fit-to-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


/* Thin Font */
.thin-font {
  font-family: 'Poppins', Helvetica, sans-serif;
  font-weight: 100;
}


/* Remove text decoration on <a> */
a.no-link {
  text-decoration: none; }
a.no-link:hover {
  text-decoration: none; }


/* Use display flex to vertically center content */
.center-content-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*flex-wrap: nowrap;*/
}